<template>
  <v-container class="mt-8 ml-2 transparent elevation-0" fluid @keydown.ctrl="abrirPegar">
    <v-row class="pa-4 textoPoppins">
      <v-col cols="12" sm="6" md="6" lg="4" class="pt-0 pr-0">
        <v-card class="transparent elevation-0">
          
          <!-- TITULO DEL TOOLBAR -->
          <v-toolbar
            class="elevation-0"
            color="#00A548"
            dark
            dense
            tile
          >
            TWILIO FAST

            <v-menu
              v-if="[334,1,568,32,718, 1183, 1258].includes( getdatosUsuario.iderp ) || vendedorasTwilio.length"
              top
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-3 ml-4"
                  x-small
                  fab
                >
                  <v-icon color="green">mdi-account</v-icon>
                </v-btn>
              </template>

              <v-list dense v-if="!vendedorasTwilio.length">
                <v-list-item
                  v-if="!vendedorasTwilio.length"
                  v-for="(item, index) in vendedoras_plantel"
                  :key="index"
                  @click="filtroVendedora = item"
                >
                  <v-list-item-title class="text-caption">
                    <v-icon color="grey">mdi-account</v-icon>
                    {{ item.nombre }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>

              <v-list dense v-else>
                <v-list-item
                  v-for="(item, index) in vendedorasTwilio"
                  :key="index"
                  @click="filtroVendedora = item"
                >
                  <v-list-item-title class="text-caption">
                    <v-icon color="grey">mdi-account</v-icon>
                    {{ item.nombre }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>


            <v-spacer></v-spacer>
            <v-btn small tile text @click="initialize">Actualizar</v-btn>
          </v-toolbar>

          <!-- SECCIÓN DE CHAT -->
          <v-card-text class="blue-grey lighten-5">
              <div v-if="filtroVendedora">{{ filtroVendedora.nombre }}</div>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  :readonly="filterChats ? false : true"
                  name="name"
                  label="Buscar chat"
                  v-model="buscarUsuario"
                  hide-details
                  dense
                  :autofocus="buscarUsuarioActive"
                  filled
                  single-line
                  prepend-inner-icon="mdi-magnify"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-select
                  dense
                  hide-details
                  single-line
                  filled
                  :items="filterFiltro"
                  label="Etapa"
                  v-model="etapa"
                  item-value="id"
                  item-text="text"
                ></v-select>
              </v-col>

              <v-col cols="12">

                <v-btn fab small @click="filtroFuego = true" class="pt-1 mr-3" :color="filtroFuego ? 'red' : ''">
                  <!-- ETAPA -->
                  <div class="text-h6">🔥</div>
                </v-btn>

                <v-btn fab small @click="filtroHielo = true" class="pt-1 mr-3" :color="filtroHielo ? 'blue' : ''">
                  <!-- ETAPA -->
                  <div class="text-h6">🧊</div>
                </v-btn>

                <v-btn rounded @click="filtroHielo = false, filtroFuego = false, filtroVistos = false, etiqueta = null, filtroTiempo = null, filtroVendedora = null " class="pt-1 elevation-4 mr-3">
                  <!-- ETAPA -->
                  Todos!!
                </v-btn>

                <v-btn fab small @click="filtroVistos =  true" class="pt-1 elevation-4 mr-3" :color="filtroVistos ? 'grey darken-2' : ''">
                  <!-- ETAPA -->
                  <v-icon :color="filtroVistos ? 'white' : 'grey darken-2'">mdi-message-bulleted-off</v-icon>
                </v-btn>

                <v-menu
                  top
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3"
                    >
                      <v-icon :color="etiqueta ? etiqueta.color : ''">mdi-checkbox-blank-circle</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item
                      v-for="(item, index) in etiquetas"
                      :key="index"
                      @click="etiqueta = item"
                    >
                      <v-list-item-title class="text-caption">
                        <v-icon :color="item.color">mdi-label</v-icon>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-menu
                  top
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      small
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-3"
                    >
                      <v-icon :color="filtroTiempo ? filtroTiempo.color : 'grey darken-2'">mdi-clock</v-icon>
                    </v-btn>
                  </template>

                  <v-list dense>
                    <v-list-item
                      v-for="(item, index) in tiempos"
                      :key="index"
                      @click="filtroTiempo = item"
                    >
                      <v-list-item-title class="text-caption">
                        <v-icon :color="item.color">mdi-clock</v-icon>
                        {{ item.text }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </v-col>

            </v-row>
          </v-card-text>

          <!-- SECCIÓN DE FILTRO PARA WHATSAPP -->
          <v-card-text class="pa-0">
            <div v-if="notSeen">
              <v-chip
                class="ma-2"
                color="pink"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-account
                </v-icon>
                {{ filterChats.length }}
              </v-chip>

              <v-chip
                class="ma-2"
                color="cyan"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-message-bulleted
                </v-icon>
                <!-- {{ filterChats.map(item => item.cant_mensajes ).reduce((prev, curr) => prev + curr, 0) }} -->
              </v-chip>
            </div>

            <ListaContactos
              :filterChats="filterChats"
              @verChat="verChat"
              :limite="limite"
            />
          </v-card-text>
        </v-card>
      </v-col>

      <!-- SECCIÓN DEL CHAT -->
      <v-col cols="12" sm="6" md="6" lg="8" class="pt-0 pl-0">

        <!-- MOSTRAR COMO QUIERA UN TOOLBAR SI NO HAY UN CHAT -->
        <v-card class="elevation-0" v-if="!chat">
          <v-toolbar
            class="elevation-0"
            color="#00A548"
            dark
            dense
            tile
          >
          </v-toolbar>
        </v-card>

        <!-- MOSTRAR SOLO SI HAY UN CHAT -->
        <v-card class="elevation-0" v-if="chat">
          <v-toolbar
            tile
            class="elevation-0"
            color="#00A548"
            dark
            dense
          >
            <!-- AVATAR -->
            <v-list-item-avatar :color="!chat.nombre ? '#00A548' : 'grey lighten-3'">
              <v-icon color="white" v-if="!chat.nombre">mdi-account</v-icon>
              <span v-if="chat.nombre">{{ chat.nombre }}</span>
            </v-list-item-avatar>
            {{ chat.usuario }}
            ---
            {{ chat.folio }}
            <v-spacer></v-spacer>

            <v-menu
              top
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  tile
                  text
                  dark
                  v-bind="attrs"
                  v-on="on"
                  v-if="chat.idetiquetas != 6"
                >
                  Categoría
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  v-for="(item, index) in etiquetas"
                  :key="index"
                  @click="cambiarCategoria( item.idetiquetas )"
                >
                  <v-list-item-title class="text-caption">
                    <v-icon :color="item.color">mdi-label</v-icon>
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>

          <section class="chat">

            <v-container
              id="scroll-target"
              :style="`max-height: ${tamanioPantalla}px`"
              class="overflow-y-auto elevation-0"
            >
              <v-row
                v-scroll:#scroll-target="onScroll"
                align="center"
                justify="center"
                style="max-height: 1000px"
              >
                <v-col cols="12">
                  <MensajesWppTwilio  
                    class="messages-chat elevation-0"
                    id="mensajesChat"
                    :mensajes="mensajes" 
                    :alumno="alumno" 
                    :wha="wha" 
                    :chat="chat" 
                    :verTodosLosMensajes="verTodosLosMensajes" 
                    :asignacion="asignacion"
                    :numeroTwilio="numeroTwilio"
                    @validayGuardaMensajes="validayGuardaMensajes"
                    :chats="getConversacionesFast"
                    @verChat="verChat"
                  />
                </v-col>
              </v-row>
            </v-container>
          </section>
        </v-card>
      </v-col>

      <!-- Dialog de carga -->
      <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
      <carga                v-if="cargar"/>
    </v-row>
  </v-container>
</template>
<script>
  import axios          from 'axios';
  import { mapGetters, mapActions } from 'vuex'
  import { io }         from "socket.io-client";

  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import MensajesWppTwilio     from '@/components/whatsapp/MensajesTwilioWpp.vue';
  import ListaContactos        from '@/components/whatsapp/ListaContactos.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import mixinWhatsApp         from '@/mixins/mixinWhatsApp'
  import mixinEstatusWha       from '@/mixins/mixinEstatusWha'

  export default {
    components:{
      Alerta,
      carga,
      MensajesWppTwilio,
      ListaContactos
    },

    mixins: [ validarErrores, mixinEstatusWha, mixinWhatsApp ],


    data: () => ({
      buscarPregunta:'',
      buscarActive:false,

      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

      usuarios:[],

      usuario: null,
      buscarUsuario:'',
      buscarUsuarioActive:true,
      alumno:null,
      url: 'https://escuelakpi.club/kpi/whatsapp-imagenes/',

      notSeen:false,
      searchMensaje:'',
      mensajes:[],
      chat:null,

      wha: null,
      notResponse:false,

      verTodosLosMensajes: 0,
      message: '',

      parametrosAsignarVendedora:{
        estatus: false
      },
      asignacion: null,
      conversaciones:[],
      asignados:[],
      loader: true,

      sonido: null,

      numeroTwilio: "whatsapp:+5218123255321",

      offsetTop: 0,

      limite: 30,

      filtroFuego: false,
      filtroHielo: false,

      filtroVistos: false,
      etapa: 8,
      etiqueta: null,

      filtroTiempo: false,
      escuela: '2',
      filtroVendedora: null,
      vendedoras_plantel:[],
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),
      ...mapGetters('twilio',['getConversacionesM','getConversacionesC', 'getConversacionesG','getConversacionesFast']),

      filterChats(){

        let chats = this.getConversacionesFast

        if( this.buscarUsuario ){
          
          chats = chats.filter( el => el.usuario.match( this.buscarUsuario ))

        }

        chats = chats.sort((a, b) => {
          // Primero, comparar 'sinleer' de mayor a menor
          if (b.sinleer !== a.sinleer) {
            return b.sinleer - a.sinleer; // Ordenar de mayor a menor
          }

          // Si 'sinleer' es igual, comparar 'ultimomensaje' de mayor a menor
          return new Date(b.ultimmensaje) - new Date(a.ultimmensaje); // Ordenar por fecha de mayor a menor
        });

        if( this.filtroFuego && !this.filtroHielo ){
          chats = chats.filter( el => el.nombre == '🔥' )
        }


        if( this.filtroHielo && !this.filtroFuego ){
          chats = chats.filter( el => el.nombre == '🧊' )
        }

        if( this.etapa && this.etapa != 8 ){

          chats = chats.filter( el => el.idetapa == this.etapa )

        }

        if( this.filtroVistos ){

          chats = chats.filter( el => el.mio == 0 )

        }

        if( this.etiqueta ){

          chats = chats.filter( el => el.idetiquetas == this.etiqueta.idetiquetas )

        }

        if( this.filtroTiempo ){

          if( this.filtroTiempo.idtiempos == 1 ){
            chats = chats.filter( el => el.tiemporestante > 2 )
          }else if( this.filtroTiempo.idtiempos == 2 ){
            chats = chats.filter( el => el.tiemporestante > 0 && el.tiemporestante <= 2 )
          }else if( this.filtroTiempo.idtiempos == 3 ){
            chats = chats.filter( el => el.tiemporestante <= 0 )
          }


        }
        return chats
      },

      filterFiltro( ){

        let filtro = [
          { id: 2, text: `Nuevo (${this.getConversacionesFast.filter( el => el.idetapa == 2).length})` },
          { id: 1, text: `Atrasado (${this.getConversacionesFast.filter( el => el.idetapa == 1).length})` },
          { id: 4, text: `Con Info (${this.getConversacionesFast.filter( el => el.idetapa == 4).length})` },
          { id: 5, text: `Sin info (${this.getConversacionesFast.filter( el => el.idetapa == 5).length})` },
          { id: 7, text: `2da Contacto (${this.getConversacionesFast.filter( el => el.idetapa == 7).length})` },
          { id: 8, text: `Todos (${this.getConversacionesFast.length})` },
        ]

        return filtro
      },

      filterVendedoras( ){
        return this.vendedoras.filter( el => el.escuela == this.escuela )
      },


      tamanioPantalla () {
        return this.$vuetify.breakpoint.height -280
      },

    },

    watch:{
      filtroVendedora( value ){

        this.initialize( )

      }
    },

    async created (){
      await this.getVendedoras();
      this.crearTemplateSonido( )
    },

    methods: {

      onScroll (e) {
        this.offsetTop = e.target.scrollTop
      },

      ...mapActions('twilio', ['guardarConversaciones', 'guardarConversacionesM', 'guardarConversacionesG', 'guardarConversacionesC', 'guardarConversacionesFast'] ),

      getNombre( texto ){
        return texto.usuario 
      },


      verChat( chat ){

        // Construimos el chat
        const payload = {
          twilio      : this.numeroTwilio,
          usuario     : chat.usuario,
        }

        // Vaciamos los mensajes
        this.cargar   = true
        this.mensajes = []

        // Hacemos la petición de los mensajes
        return this.$http.post('twilio.chats.unico', payload ).then(response=>{

          // Guardamos los mensajes
          this.mensajes   = response.data.conversacionCompleta
          let bloqueo     = response.data.bloqueo

          let conversaciones = [...this.getConversacionesFast];

          // Si existe.... hay que cambiarle el estatus
          for( const i in conversaciones ){

            const { usuario } = conversaciones[i]

            if( usuario == chat.usuario ){

              conversaciones[i].sinleer = 0

            }

          }


          if( bloqueo ){
            chat.idetiquetas = 6
          }

          this.chat = chat

          this.guardarConversacionesFast( conversaciones )

          // Lo mandamos hasta abajo el scroll
          setTimeout(function(){
            document.getElementById('scroll-target').scrollTop = document.getElementById('scroll-target').scrollHeight
          },300)

          this.cargar   = false // termina el cargar

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirPegar( event ){
        console.log( event )
      },

    },
  }
</script>

<style  scoped>

  .textoPoppins{
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px;
    font-weight: 300;
  }

  #scroll-target::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  #scroll-target::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #scroll-target::-webkit-scrollbar-button:increment,#scroll-target::-webkit-scrollbar-button{
    display: none;
  }

  #scroll-target::-webkit-scrollbar:horizontal{
    display: none;
  }

  #scroll-target::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  #preguntas::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #preguntas::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #preguntas::-webkit-scrollbar-button:increment,#preguntas::-webkit-scrollbar-button{
    display: none;
  }

  #preguntas::-webkit-scrollbar:horizontal{
    display: none;
  }

  #preguntas::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  /**********************************/

  #mensajesChat::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > #mensajesChat::-webkit-scrollbar:vertical {
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar:vertical{
    width: 4px !important;
  }

  #mensajesChat::-webkit-scrollbar-button:increment,#mensajesChat::-webkit-scrollbar-button{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar:horizontal{
    display: none;
  }

  #mensajesChat::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .container {
    padding:0;
    background-color: #FFF; 
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    height: 750px;
    margin-top: 25px;
  }

  /* ===== MENU ===== */
  .menu {
    float: left;
    height: 700px;;
    width: 70px;
    background: #4768b5;
    background: -webkit-linear-gradient(#4768b5, #35488e);
    background: -o-linear-gradient(#4768b5, #35488e);
    background: -moz-linear-gradient(#4768b5, #35488e);
    background: linear-gradient(#4768b5, #35488e);
    box-shadow: 0 10px 20px rgba(0,0,0,0.19);
  }

  .menu .items {
    list-style:none;
    margin: auto;
    padding: 0;
  }

  .menu .items .item {
    height: 70px;
    border-bottom: 1px solid #6780cc;
    display:flex;
    justify-content: center;
    align-items: center;
    color: #9fb5ef;
    font-size: 17pt;
  }

  .menu .items .item-active {
    background-color:#5172c3;
    color: #FFF;
  }

  .menu .items .item:hover {
    cursor: pointer;
    background-color: #4f6ebd;
    color: #cfe5ff;
  }

  /* === CONVERSATIONS === */

  .discussions {
    width: 100%;
    height: 702px;
    box-shadow: 0px 8px 10px rgba(0,0,0,0.20);
    overflow: hidden;
    display: inline-block;
  }

  .discussions .discussion {
    width: 100%;
    height: 90px;
    background-color: #FAFAFA;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search {
    display:flex;
    align-items: center;
    justify-content: center;
    color: #E0E0E0;
  }

  .discussions .search .searchbar {
    height: 40px;
    background-color: #FFF;
    width: 70%;
    padding: 0 20px;
    border-radius: 50px;
    border: 1px solid #EEEEEE;
    display:flex;
    align-items: center;
    cursor: pointer;
  }

  .discussions .search .searchbar input {
    margin-left: 15px;
    height:38px;
    width:100%;
    border:none;
    font-family: 'Montserrat', sans-serif;;
  }

  .discussions .search .searchbar *::-webkit-input-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *::-moz-placeholder {
      color: #E0E0E0;
  }
  .discussions .search .searchbar input *:-ms-input-placeholder {
      color: #E0E0E0;
  }

  .discussions .message-active {
    height: 90px;
    background-color: #FFF;
    border-right: solid 5px #E91E63;
  }

  .discussions .discussion .photo {
    margin-left:20px;
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .online {
    position: relative;
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    background-color: #8BC34A;
    border-radius: 13px;
    border: 3px solid #FAFAFA;
  }

  .desc-contact {
    height: 43px;
    width:50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .discussions .discussion .name {
    margin: 0 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 11pt;
    color:#515151;
  }

  .discussions .discussion .message {
    margin: 6px 0 0 20px;
    font-family:'Montserrat', sans-serif;
    font-size: 9pt;
    color:#515151;
  }

  .timer {
    margin-left: 15%;
    font-family:'Open Sans', sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #BBB;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
  }

  .chat {
    width: calc(100%);
  }

  .chat .messages-chat {
    padding: 25px 10px;
  }

  .chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
  }

  .text-only {
    white-space: pre-line;
  }

  .time {
    font-size: 12px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 38px;
  }

  .response-time {
    float: right;
    margin-right: 40px !important;
  }

  .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
    max-width: 90%;
  }

  .noresponse {
    max-width: 90%;
  }

  .response .text {
    background-color: #E91E63 !important;
    color: white;
  }

  .footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;
    
  }

  .chat .footer-chat .icon {
    margin-left: 30px;
    color:#C0C0C0;
    font-size: 14pt;
  }

  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }

</style>
